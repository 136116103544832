import chileFlag from "../static/svgs/chileFlag.svg";
import peruFlag from "../static/svgs/peruFlag.svg";
import colombiaFlag from "../static/svgs/colombiaFlag.svg";

const staticConstants = {
  MIN_NUMBER_RUT_LEGAL_PERSON: 50000000,
  SUPPORT_EMAIL: "equipoadquisicion@falabella.com",
  REGISTRATION_GUIDE_URL: "https://ayudaseller.falabella.com/s/article/Como-registrarte-como-seller-en-falabella-com",
  DEFAULT_DISCLAIMER_MESSAGE_CONFIGURATION: {
    CL: {
      formUrl: "https://docs.google.com/forms/d/e/1FAIpQLScqh_Hd1GspqXbGGc2KAvaBQsv1qATjL4cnsILQWGKdp0NB9w/viewform?usp=sharing",
      bannedCategories: "vestuario y calzado"
    },
    CO: {
      formUrl: "https://docs.google.com/spreadsheets/d/1IxZLaOYIVI3lnpoLjvtfEdNsoizHzj4c-pbY9gx5aD0/edit?usp=drivesdk",
      bannedCategories: "vestuario, calzado y/o perfumería"
    },
    PE: {
      formUrl: "",
      bannedCategories: ""
    }
  },
  VALID_COUNTRIES: {
    CL: {
      countryCode: "CL",
      phoneCode: "+56",
      name: "Chile",
      logo: chileFlag,
      operatorCode: "facl"
    },
    PE: {
      countryCode: "PE",
      phoneCode: "+51",
      name: "Peru",
      logo: peruFlag,
      operatorCode: "fape"
    },
    CO: {
      countryCode: "CO",
      phoneCode: "+57",
      name: "Colombia",
      logo: colombiaFlag,
      operatorCode: "faco"
    }
  },
  DEFAULT_BUSINESS_REGISTRATION_TYPE: {
    CL: "RUT",
    CO: "NIT",
    PE: "RUC"
  },
  FORM_REGISTRATION_STATUS: {
    default: "Incomplete",
    error: "Error",
    success: "Successful"
  },
  SECTION_TYPE_ACCORDION: "Accordion",
  ACCORDION_ITEM_ENABLED_CLASS: "accordion-item-enabled",
  ACCORDION_ITEM_DISABLED_CLASS: "accordion-item-disabled",
  ADOBE_ANALYTICS_URL: `${process.env.REACT_APP_ADOBE_SCRIPT_URL}`,
  NATURAL_PERSON_VALUE: "natural_person",
  NATURAL_PERSON_LABEL: "Persona natural",
  NATURAL_PERSON: "Natural person",
  LEGAL_PERSON_VALUE: "legal_person",
  LEGAL_PERSON_LABEL: "Persona jurídica",
  LEGAL_PERSON: "Legal person",
  FOLLOWING_BUTTON_ACTION_OVERWRITE_FORM_CONFIG: "FOLLOWING_BUTTON_ACTION_OVERWRITE_FORM_CONFIG",
  TYPE_OF_PERSON: "legalForm",
  NO: "No",
  YES: "Yes",
  SI: "Sí",
  SI_WITHOUT_ACCENT_MARK: "Si",
  VALIDATION_MESSAGE_SPANISH_FPAY_EXISTING_ACCOUNT:
    "Este correo ya ha sido utilizado para una cuenta de Fpay, a través de la cual recibirás tus pagos. Para continuar debes ingresar uno distinto.",
  END_POINT_ECONOMIC_ACTIVITY: "economicActivity",
  END_POINT_REGIONS: "regions",
  END_POINT_CITY: "city",
  END_POINT_FACO_COUNTRY_CITY: "facoCountryCity",
  END_POINT_MUNICIPAL: "municipal",
  END_POINT_FACL_REGION_MUNICIPALS: "faclRegionMunicipals",
  END_POINT_BANKS: "sellerBanks",
  END_POINT_UPLOAD_DOCUMENT: "uploadFormDocument",
  END_POINT_SELLER: "seller",
  END_POINT_FPAY_EMAIL_VALIDATION: "validateEmailFpay",
  INPUT_TYPE_TEXT: "text",
  INPUT_TYPE_DROPDOWN: "dropdown",
  INPUT_TYPE_DROPDOWN_SEARCH: "dropdownSearch",
  INPUT_TYPE_RADIO: "radio",
  INPUT_TYPE_CHECKBOX: "checkbox",
  INPUT_TYPE_TEXTAREA: "textarea",
  INPUT_TYPE_FILE: "file",
  INPUT_TYPE_BUTTON: "button",
  INPUT_TYPE_SUBMIT: "submit",
  INPUT_TYPE_RESET: "reset",
  INPUT_TYPE_FIELDGROUP: "fieldGroup",
  INPUT_TYPE_LABEL: "label",
  INPUT_TYPE_DROPFILE: "dropfile",
  INPUT_TYPE_SEARCH_TEXT_BOX: "searchTextBox",
  INPUT_TYPE_LEGEND: "legend",
  INPUT_TYPE_CUSTOM_HTML: "customHtml",
  INPUT_TYPE_THUMBNAILDROPDOWN: "thumbnailDropdown",
  SERVICE_TEST: "TEST",
  SERVICE_BFF: "BFF",
  PARAM_TYPE_STATIC_VALUE: "StaticValue",
  PARAM_TYPE_GLOBAL_STATE_VALUE: "GlobalStateValue",
  DATASOURCE_TYPE_JSON: "JSON",
  DATASOURCE_TYPE_API: "API",
  ACTION_HANDLER_TOGGLE_VISIBILITY: "toggleFieldVisibility",
  ACTION_HANDLER_FOCUS_SUBSECTION: "FocusSubSection",
  ACTION_HANDLER_RENDER_SUBTITLE_AND_FOCUS_SUBSECTION: "RenderSubTitleAndFocusSubsection",
  ACTION_HANDLER_RENDER_SUBTITLE_AND_ACTIVATE_PAGE_NAVIGATION: "RenderSubTitleAndActivatePageNavigation",
  ACTION_HANDLER_ACTIVATE_PAGE_NAVIGATION: "ActivatePageNavigation",
  INPUT_HANDLER_FORMATTED_RUT_NATURAL_PERSON: "getFormattedRutNaturalPerson",
  INPUT_HANDLER_FORMATTED_RUT_LEGAL_PERSON: "getFormattedRutLegalPerson",
  INPUT_HANDLER_ONLY_NUMBERS: "getOnlyNumbers",
  INPUT_HANDLER_ONLY_LETTERS_AND_SPACES: "getOnlyLettersAndSpaces",
  INPUT_HANDLER_ONLY_LETTERS_AND_NUMBERS: "getOnlyLettersAndNumbers",
  INPUT_HANDLER_ONLY_LETTERS_NUMBERS_AND_SPACES: "getOnlyLettersNumbersAndSpaces",
  INPUT_HANDLER_ONLY_LETTERS_NUMERS_SPACES_AND_ESPECIAL: "getOnlyLettersNumbersSpacesAndSpecialCharacters",
  INPUT_HANDLER_ONLY_VALID_EMAILS_CHARS: "getValidEmailCharacters",
  VALIDATOR_RUT: "RutValidation",
  VALIDATOR_RUT_IS_NATURAL_PERSON: "RutIsNaturalPerson",
  VALIDATOR_RUT_IS_LEGAL_PERSON: "RutIsLegalPerson",
  VALIDATOR_REGEX: "Regex",
  VALIDATOR_EMAIL: "Email",
  VALIDATOR_FILE_TYPE: "FileType",
  VALIDATOR_FILE_SIZE: "FileSize",
  VALIDATOR_HAS_CURRENT_VALUE: "hasCurrentValue",
  VALIDATOR_EQUAL_FIELD_VALUE: "EqualFieldValue",
  VALIDATOR_CONFIRMATION_FIELD_VALUE: "confirmationFieldValue",
  VALIDATOR_VALID_OPTION: "validOption",
  VALIDATOR_IS_NUMERIC: "IsNumeric",
  VALIDATOR_MIN_LENGTH: "MinLength",
  VALIDATOR_MAX_LENGTH: "MaxLength",
  VALIDATOR_MAX_VALUE: "MaxValue",
  VALIDATOR_MIN_VALUE: "MinValue",
  VALIDATOR_IS_CHECKED: "IsChecked",
  VALIDATOR_NOT_EMPTY: "NotEmpty",
  INPUT_ID_BUSSINESS_REG_NUMBER: "rutCompany",
  INPUT_ID_ADMIN_FIRST_NAME: "adminFirstName",
  INPUT_ID_ADMIN_LAST_NAME: "adminLastName",
  INPUT_ID_ADMIN_DOCUMENT_TYPE: "adminDocumentType",
  INPUT_ID_ADMIN_DOCUMENT_NUMBER: "adminDocumentNumber",
  INPUT_ID_ADMIN_EMAIL: "adminEmail",
  INPUT_ID_ADMIN_PHONE: "adminPhone",
  INPUT_ID_LEGAL_REPRESENTATIVE_NAME: "legalRepName",
  INPUT_ID_LEGAL_REPRESENTATIVE_LASTNAME: "legalRepLastName",
  INPUT_ID_LEGAL_REPRESENTATIVE_DOCUMENT_TYPE: "legalRepDocumentType",
  INPUT_ID_LEGAL_REPRESENTATIVE_DOCUMENT_NUMBER: "legalRepDocumentNumber",
  INPUT_ID_LEGAL_REPRESENTATIVE_DOCUMENT_FILE: "documentIdentification",
  INPUT_ID_LEGAL_REPRESENTATIVE_EMAIL: "legalRepEmail",
  INPUT_ID_LEGAL_REPRESENTATIVE_PHONE: "legalRepPhone",
  INPUT_ID_SHIPMENT_WAREHOUSE_CONTACT_NAME: "shipmentWarehouseContactName",
  INPUT_ID_SHIPMENT_WAREHOUSE_CONTACT_PHONE: "shipmentWarehouseContactPhone",
  INPUT_ID_SHIPMENT_WAREHOUSE_CONTACT_EMAIL: "shipmentWarehouseContactEmail",
  INPUT_ID_RETURN_WAREHOUSE_CONTACT_NAME: "returnWarehouseContactName",
  INPUT_ID_RETURN_WAREHOUSE_CONTACT_PHONE: "returnWarehouseContactPhone",
  INPUT_ID_RETURN_WAREHOUSE_CONTACT_EMAIL: "returnWarehouseContactEmail",
  INPUT_ID_DOCUMENT_RUT: "documentRUT",
  INPUT_ID_DOCUMENT_BANK_CERTIFICATE: "documentBankCertificate",
  INPUT_ID_SELL_IPORTED_PRODUCTS: "importedProducts",
  INPUT_ID_IN_PROGRAM_AML: "inProgramAML",
  ADDITIONAL_FLOWS_COUNTRYARR: ["CO"]
};

const constants = {
  ...staticConstants,
  TYPE_OF_PERSON_MAP: {
    [staticConstants.LEGAL_PERSON_VALUE]: staticConstants.LEGAL_PERSON,
    [staticConstants.NATURAL_PERSON_VALUE]: staticConstants.NATURAL_PERSON
  },
  DOCUMENTS_PER_COUNTRY: {
    CL: {
      [staticConstants.INPUT_ID_LEGAL_REPRESENTATIVE_DOCUMENT_FILE]: "tax_id_legal_representative"
    },
    CO: {
      documentBankCertificate: "bank_certificate",
      documentRUT: "document_rut",
      [staticConstants.INPUT_ID_LEGAL_REPRESENTATIVE_DOCUMENT_FILE]: "tax_id_legal_representative",
      deedCompanyIncorporation: "deed_company_incorporation"
    }
  }
};

export default Object.freeze(constants);
